<template>
  
  <div class="d-flex no-block justify-content-center align-items-center" >
    <div class="back"></div> 
    <notifications group="container" position="top right" :duration="5000" :max="5" :ignoreDuplicates="true" :closeOnClick="true" class="mt-3 mr-3" />
    
    
      <CRow class="auth-wrapper d-flex justify-content-center align-self-stretch auth-box" >

            <div class="p-2 box">
              <div class="logo"></div>
              <CCardBody>
               
                <transition name="slide-fade" mode="out-in">
                  <CForm v-if="!validate">
                    <h2 class="text-center mb-4 pb-4 black-letter">Sistema de Gestión de Terminal</h2>
                     
                    <div class="form-group" role="group">
                      
                      <div class="input-group input-field es-valido" :class="inputClasses($v.user.Login)">
                        <label class="input-group-text input-icon-prepend" for="username">
                          <i class="fas fa-user" ></i>
                        </label>
                        <input
                          :value="$v.user.Login.$model"
                          @input="$v.user.Login.$model = $event.target.value.toUpperCase()"
                          id="username"
                          type="text"
                          class="form-control input-icon"                                
                          autocomplete="username"
                          required
                        >
                        <label for="username" class="class-label black-letter">Nombre de Usuario</label>
                        <div class="invalid-feedback">
                          Campo requerido: solo letras.
                        </div>
                      </div>
                       
                    </div>
                    <div class="form-group  mt-5" role="group">
                      
                      <div class="input-group input-field" :class="inputClasses($v.user.Password)">
                        <label class="input-group-text input-icon-prepend" for="password">
                          <i class="fas fa-lock" ></i>
                        </label>
                        <input
                          v-model="$v.user.Password.$model"
                          id="password"
                          :type="inputType"
                          class="form-control input-icon-prep"
                          autocomplete="current-password"
                          required
                        >
                        <label for="password" class="class-label black-letter">Contraseña</label>
                        <label
                          for="password"
                          class="btn input-group-text input-icon-append"
                          @click.prevent="togglePassword"
                        >
                          <CIcon v-if="!showPassword" name="cil-eye"/>
                          <CIcon v-if="showPassword" name="cil-eye-slash"/>
                        </label>
                        <div class="invalid-feedback">
                          Invalid length
                        </div>
                      </div>
                      
                    </div>
                    <div class="position-relative black-letter-select">
                      <CSelect
                        :value.sync="connection"
                        placeholder="CONEXION"
                        label="ORIGEN DE DATOS"
                        :options="getConnections"
                        invalid-feedback="Campo requerido."
                        :is-valid="hasError($v.connection)"
                        required
                      />
                    </div>
                    <div class="position-relative black-letter-select">
                      
                      <CSelect
                      id="empresa"
                        :disabled="isCompaniesEmpty"
                        :value.sync="$v.user.CompanyId.$model"
                        placeholder="Seleccione una Empresa"
                        label="EMPRESA"
                        :options="companyOptions"
                        invalid-feedback="Campo requerido."
                        :is-valid="hasError($v.user.CompanyId)"
                      />
                      <CElementCover
                        :opacity="0.8"
                        v-show="isLoadingCompanies"
                      >
                        <label class="d-inline">{{$t('label.load')}}... </label>
                        <CSpinner size="sm" />
                      </CElementCover>
                    </div>
                    <CRow>
                      <CCol sm="6">
                        <CButton
                          :disabled="apiUrl == ''"
                          class="px-0 float-right italic-letter"
                          @click.prevent="recovery"
                        >
                          ¿Olvido Contraseña?
                        </CButton>
                      </CCol>
                      <CCol sm="6">
                        <CButton
                        style="background-color: #42AA91; border-color:#42AA91; color: white; font-weight: bold;"
                        block
                        class="px-4"
                        :disabled="isSubmitValid"
                        @click="submit"
                      >
                        <div v-if="!isSubmit">
                          <span>Iniciar Sesión</span>
                        </div>
                        <div v-if="isSubmit">
                          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="ml-1">Iniciar Sesión</span>
                        </div>
                      </CButton>
                      </CCol>
                      
                    </CRow>
                  </CForm>

                  <CRow v-else alignHorizontal="center">
                    <CCol sm="10" class="mb-2">
                      <p class="font-weight-bold parrafo text-justify">
                        Estimado Usuario: {{ user.Login }}, se ha generado el Código de Validación el cuál estará activo por un lapso de 30 minutos.
                      </p>
                    </CCol>
                    <CCol sm="12" class="d-flex flex-column align-items-center mt-2">
                      <CButton 
                        shape="square"
                        color="info"
                        class="d-flex align-items-center"
                        :disabled="isValid"
                        @click.prevent="goToValidate"
                      >
                        VALIDAR USUARIO
                      </CButton>
                      <CButton
                        color="link"
                        class="pt-1 text-black-50"
                        @click.prevent="backToLogin"
                      >
                        Regresar al Login
                      </CButton>
                    </CCol>
                  </CRow>
                </transition>
              </CCardBody>
            </div>
          
        
      </CRow>
    
  
  </div>
 
</template>

<script>
import { mapState } from 'vuex';
import AuthService from '@/_services/auth-service';
import LoginFormValidations from '@/_validations/auth/LoginFormValidations';
import GeneralMixin from '@/_mixins/general';
import AuthMixin from '@/_mixins/auth';
import DataStorage from '@/_helpers/localStorage';
import { connectionsList } from '@/_helpers/connections';

//Data
function data() {
  return {
    isSubmit: false,
    isLoadingCompanies: false,
    isValid: false,
    validate:  false,
    validateToken: '',
    showPassword: false,
    user: {
      Login: '',
      Password: '',
      CompanyId: '',
      Connection: '',
    },
    connection: '',
    imagen: document.location.origin +"/src/assets/icons/sigep.png",
    empresas: [],
    empresa: null,
  }
}

//Created
function created() {
  if(this.loggedIn) DataStorage.deleteUser();
  // this.getCompanies();
  //this.$router.push({ name: 'RoleSelection' });
}

//Methods:
function submit() {
  this.isSubmit = true;
  this.$store.dispatch('auth/login', this.user)
  .then((response) => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.msg,
      type: "success"
    });
    if(response.FgFirstTime == 1){
      this.validate = !this.validate;
      this.validateToken = response.token;
    }else if(response.RoleJson.length == 1 && response.BranchJson.length == 1) {
      DataStorage.setInitConfiguration({
        role: response.RoleJson[0],
        branch: response.BranchJson[0],
        routesByRole: []
      });
      this.$store.dispatch('auth/setBranch', response.BranchJson[0]);
      this.generateMenu(response.RoleJson[0].RoleId);
    }else {
      DataStorage.setInitConfiguration({
        role: null,
        branch: null,
        routesByRole: []
      });
      this.$router.push({ name: 'RoleSelection' });
    }
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.isSubmit = false;
  });
}
function generateMenu(roleId) {
  this.$store.dispatch('menu/getMenuInfo', roleId)
  .then((response) => {
    this.$router.push({ name: 'Dashboard' });
  })
  .catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
function backToLogin() {
  this.$store.dispatch('connection/delete');
  this.validate = false;
  this.user.Login = '';
  this.user.Password = '';
  this.user.CompanyId = '';
  this.user.connection = '';
  this.showPassword = false;
  this.isSubmit = false;
  this.$v.$reset();
}
function getCompanies() {
  this.isLoadingCompanies = true;

  AuthService.companyList()
  .then((response) => {
    this.empresas = response.data.data[0].Json;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingCompanies = false;
  });
}
function recovery() {
  this.$router.push({ name: 'Recovery' });
}
function goToValidate() {
  this.isValid = true;
  this.$router.push({ path: '/validate', query: { 
      token: this.validateToken, CiLogin: this.user.Login
    } 
  });
}
function checkRepository(url) {
  AuthService.checkRepository(url)
  .then((response) => {
    this.$store.dispatch('connection/assign', url);
    this.user.Connection = url; 
    this.getCompanies();
  }).catch((err) => {
    this.connection = '';
    this.$store.dispatch('connection/delete');
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: 'ERROR CONEXION ORIGEN DE DATOS',
      type: "error"
    });
  });
}

//Computed: 
function loggedIn() {
  return this.$store.state.auth.status.loggedIn;
}
function isCompaniesEmpty() {
  return this.empresas.length == 0;
}
function companyOptions() {
  return this.empresas.map((empresa) => Object.assign({}, empresa, {
    label: empresa.CompanyName,
    value: empresa.CompanyId
  }));
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function getConnections() {
  return connectionsList();
}

export default {
  name: 'Login',
  mixins: [GeneralMixin, AuthMixin],
  data,
  validations: LoginFormValidations,
  created,
  methods: {
    getCompanies,
    recovery,
    backToLogin,
    submit,
    goToValidate,
    generateMenu,
    checkRepository,
  },
  computed: {
    isCompaniesEmpty,
    companyOptions,
    loggedIn,
    isSubmitValid,
    getConnections,
    ...mapState({
        apiUrl: state => state.connection.apiUrl,
    })
  },
  watch: {
    connection: function(newVal) {
      this.checkRepository(newVal);
    },
  }
}
</script>

<style scoped>

.class-label {
  position: absolute;
  top: 7px;
  left: 33px;
  width: 100%;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
  z-index: 5;
}

.input-icon {
  padding-left: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prep {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  border-radius: 0.25rem !important;
}
.input-icon-prepend {
  position: absolute;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  border-radius: 0.25rem;
  z-index: 5;
}
.input-icon-append {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  border: transparent;
  z-index: 5;
  box-shadow: none;
}


.es-invalido .invalid-feedback {
  display: block;
}

.parrafo {
  font-size: medium;
}

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.box{
  width: 90%;
  opacity: 1;
}

.auth-wrapper .auth-box.on-sidebar {
  
    top: 0;
    right: 0;
    height: 100%;
    margin: 0;
    position: absolute;
}

.auth-box {
  min-width: 500px ;
    background: #D2CFCA;
   z-index: 2;
   height: 100% !important;
   position:absolute;
   border-left: 8px #EC631B solid;
   border-right: 8px #EC631B solid;
}

.input-field {
  position: relative;
}


.es-valido .form-control,
.es-valido .form-control:focus{
  border-color: #2eb85c;
}

.es-invalido .form-control,
.es-invalido .form-control:focus{
  border-color: #e55353;
}

.input-field:hover .input-group-text{
  color: #2eb85c;
}
.input-field .form-control:focus,
.input-field:not(.es-invalido):hover .form-control{
  border-color: #2eb85c !important;
  box-shadow: 0 0 0 .2rem rgba(46,184,92,.25);
}
.es-invalido:hover .input-group-text {
  color: #e55353;
}

.es-invalido .form-control:focus,
.es-invalido:hover .form-control{
  border-color: #e55353;
  box-shadow: 0 0 0 .2rem rgba(229,83,83,.25);
}


input:focus~.class-label,
input:valid~.class-label  {
  font-size: 14px;
  top: -24px;
  left: 0;
  color: #3c4b64;
}

.black-letter{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
   font-weight: bold;
}

.black-letter-select{
  color: #3c4b64;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  font-size: 14px !important;
}

h2{
  font-weight: bold;
  font-size: 28px;
}

#username{
   font-family: 'Barlow Condensed', sans-serif;
   font-size: 15px;
   color: #56585e;
}

.italic-letter{
  font-family: 'Barlow Condensed', sans-serif !important;
  font-weight:lighter;
  font-style: italic;
  font-size: 14px;
}

#empresa{
   font-family: 'Barlow Condensed', sans-serif;
   font-size: 15px;
   color: black;
}
</style>